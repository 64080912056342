import React, { useState, useEffect } from "react";
import { Box, Grid, Button } from "@amzn/awsui-components-react";
import { Route } from "react-router-dom";
import Cookies from 'js-cookie'

import ConvertAccountIdToToken from "./ConvertAccountIdToToken";
import StreamKonaFiles from "./StreamKonaFiles.jsx";
import MeteringAmendmentFiles from "./MeteringAmendmentFiles.jsx";
import MoveSQS from "./MoveSQS.jsx";
import WeighAwayAZ from "./WeighAwayAZ";
import WeighInAZ from "./WeighInAZ";
import WorkshopHome from "./WorkshopHome.jsx";

import "@amzn/awsui-global-styles/polaris.css";

import { Auth, Hub, Amplify } from "aws-amplify";

import config from "../auth-exports";

Amplify.configure(config);

const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      // eslint-disable-next-line default-case
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          getUser().then((userData) => setUser(userData));
          break;
        case "signOut":
          setUser(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
      }
    });

    getUser().then((userData) => {
      console.log(userData);
      setUser(userData);
    });
  }, []);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => console.log("Not signed in"));
  }

  return (
    <div>
      {user ? (
        <div>
          <div>
            <Route exact path="/" component={WorkshopHome} />
            <Route path="/MoveSQSMessages" component={MoveSQS} />
            <Route path="/StreamKonaFiles" component={StreamKonaFiles} />
            <Route path="/MeteringAmendmentFiles" component={MeteringAmendmentFiles} />
            <Route path="/WeighAwayAZ" component={WeighAwayAZ} />
            <Route path="/WeighInAZ" component={WeighInAZ} />
            <Route path="/ConvertAccountIdToToken" component={ConvertAccountIdToToken} />
          </div>
        </div>
      ) : (
        <Grid gridDefinition={[{ colspan: 6, offset: 3 }]}>
          <Box className="login-form" textAlign="center" color="inherit">
            <h1 color="#ff9900;">Welcome to your new Internal App!</h1>
            <Button
              variant="primary"
              onClick={() =>
                Auth.federatedSignIn({ provider: "AmazonFederate" })
              }
            >
              Login With Amazon Midway
            </Button>
          </Box>
        </Grid>
      )}
      ;
    </div>
  );
};

export default App;
