import React, { useEffect, useState } from "react";
import {
    AppLayout,
    SideNavigation,
    Container,
    Header,
    HelpPanel,
    Grid,
    Box,
    TextContent,
    SpaceBetween,
    Select,
} from "../aws-ui-components";
import "../styles/intro.scss";
import "../styles/servicehomepage.scss";
import Cookies from 'js-cookie';
import Button from "@amzn/awsui-components-react/polaris/button";
import DatePicker from "@amzn/awsui-components-react/polaris/date-picker";
import Form from "@amzn/awsui-components-react/polaris/form";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input from "@amzn/awsui-components-react/polaris/input";
import Multiselect from "@amzn/awsui-components-react/polaris/multiselect";
import TimeInput from "@amzn/awsui-components-react/polaris/time-input";

export default function WorkshopHome() {
    const [counter, setCounter] = useState(null);
    //TODO
    useEffect(() => {
        const fetchData = async () => {
            const token = (await Auth.currentSession()).getIdToken().getJwtToken();
            console.log(token);
            const response = await fetch(
                `https://api.${window.location.hostname}/workshop`,
                {
                    method: "GET",
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            const data = await response.json();
            console.log(data);
            setCounter(data);
        };
        fetchData().catch(console.error);
    }, []);

    return (
        <AppLayout
            navigation={<CustomNavigation />}
            content={counter ? <Content counter={counter} /> : <Content counter="" />}
            tools={<Tools />}
            disableContentPaddings={true}
        />
    );
}

const Content = (props) => {
    const today = new Date();
    const todayMinusOneHour = new Date(Date.now() - ( 3600 * 1000 ));
    const [startTime, setStartTime] = useState(todayMinusOneHour.toLocaleTimeString('it-IT'));
    const [endTime, setEndTime] = useState(today.toLocaleTimeString('it-IT'));
    const [startDate, setStartDate] = useState(today.toISOString().split('T')[0]);
    const [endDate, setEndDate] = useState(today.toISOString().split('T')[0]);
    const [region, setRegion] = useState({label: 'us-east-1'});
    const [stage, setStage] = useState({label: 'prod'});
    const [selectedOptions, setSelectedOptions] = useState([
        {
            label: "Data Ingestion",
            value: "Data_Ingestion",
        },
        {
            label: "Data Query",
            value: "Data_Query",
        },
        {
            label: "Notification",
            value: "Notification",
        },
        {
            label: "Dependency Routing",
            value: "DependencyRouting"
        }
    ]);
    const [credentials, setCredentials] = useState();
    const [admsCredentials, setAdmsCredentials] = useState();
    const [email, setEmail] = useState();

    let handleSubmit = async (e) => {
        console.log(e);
        e.preventDefault();
        console.log("Querying...");
        alert(Cookies.get("CognitoIdentityServiceProvider.3j077bo0mkec7pi3fv0hitifvl.AmazonFederate_cvmoore.userData"))
        let res = await fetch("https://gbuwea5qmd.execute-api.us-west-2.amazonaws.com/default/handleRequest", {
            mode: "no-cors",
            method: "POST",
            body: JSON.stringify({
                "region": region['label'],
                "stage": stage['label'],
                "selectedOptions": selectedOptions,
                "startTime": startTime,
                "startDate": startDate,
                "endTime": endTime,
                "endDate": endDate,
                "admsCredentials": credentials,
                "credentials": admsCredentials,
                "email" : email
            })
        })  .then((res) => res.json())
            .then((data) => console.log(data))
            .catch(error => console.log(error));
    };

    return(
        <div>
            <TextContent>
                <div>
                    <Grid className="custom-home__header" disableGutters={true}>
                        <Box margin="xxl" padding={{ vertical: "xl", horizontal: "l" }}>
                            <div className="custom-home__header-title">
                                <Box fontSize="display-l" fontWeight="bold" color="inherit">
                                    My Workshop Application
                                </Box>
                                <Box
                                    fontSize="display-l"
                                    padding={{ bottom: "s" }}
                                    fontWeight="light"
                                    color="inherit"
                                >
                                    Proof of Concept for Runtime DevOps Tool
                                </Box>
                                <Box fontWeight="light">
                                    <span className="custom-home__header-sub-title" />
                                </Box>
                            </div>
                        </Box>
                    </Grid>
                </div>

                <Box margin="xxl" padding="l">
                    <SpaceBetween size="l">
                        <h1>Customer Impact Tool</h1>
                        <Container>
                            <div>
                                Generates a report with detailed customer impact for different runtime services. Once all fields are properly filled out
                                <br/>
                                and the form is submitted, then the Customer Impact report will appear in markdown form.
                                <br/>
                            </div>
                        </Container>

                        <Container>
                            <form onSubmit={handleSubmit}>
                                <Form
                                    actions={
                                        <SpaceBetween direction="horizontal" size="xs">
                                            <Button variant="primary">Submit</Button>
                                        </SpaceBetween>
                                    }
                                >
                                    <FormField label="Choose a Region">
                                        <Select
                                            selectedOption = {region}
                                            onChange={event => setRegion(event.detail.selectedOption)}
                                            options={[
                                                {label: 'us-east-1'},
                                                {label: 'us-west-2'},
                                                {label: 'eu-west-1'},
                                                {label: 'eu-central-1'},
                                                {label: 'ap-southeast-1'},
                                                {label: 'ap-southeast-2'},
                                                {label: 'ap-northeast-1'},
                                                {label: 'ap-northeast-2'},
                                                {label: 'ap-south-1'},
                                                {label: 'us-east-2'},
                                                {label: 'ca-central-1'},
                                            ]}
                                            placeholder="Choose a Region"
                                        />
                                    </FormField>
                                    <br/>
                                    <br/>
                                    <FormField label="Choose a Stage">
                                        <Select
                                            selectedOption = {stage}
                                            onChange={event => setStage(event.detail.selectedOption)}
                                            options={[
                                                {label: 'beta'},
                                                {label: 'gamma'},
                                                {label: 'prod'},
                                                {label: 'alpha'}
                                            ]}
                                            placeholder="Choose a Stage"
                                        />
                                    </FormField>
                                    <br/>
                                    <br/>
                                    <FormField label="Choose Components">
                                        <Multiselect
                                            selectedOptions={selectedOptions}
                                            onChange={event => setSelectedOptions(event.detail.selectedOptions)}
                                            deselectAriaLabel={e => `Remove ${e.label}`}
                                            options={[
                                                {
                                                    label: "Data Ingestion",
                                                    value: "Data_Ingestion",
                                                },
                                                {
                                                    label: "Data Query",
                                                    value: "Data_Query",
                                                },
                                                {
                                                    label: "Notification",
                                                    value: "Notification",
                                                },
                                                {
                                                    label: "Dependency Routing",
                                                    value: "DependencyRouting"
                                                }
                                            ]}
                                            placeholder="Choose Components"
                                            selectedAriaLabel="Selected"
                                        />
                                    </FormField>
                                    <br/>
                                    <br/>
                                    <FormField label="Start Date">
                                        <DatePicker
                                            onChange={event => setStartDate(event.detail.value)}
                                            value={startDate}
                                            openCalendarAriaLabel={selectedDate =>
                                                "Choose Date" +
                                                (selectedDate
                                                    ? `, selected date is ${selectedDate}`
                                                    : "")
                                            }
                                            nextMonthAriaLabel="Next month"
                                            placeholder="YYYY/MM/DD"
                                            previousMonthAriaLabel="Previous month"
                                            todayAriaLabel="Today"
                                        />
                                    </FormField>
                                    <FormField label="Start Time">
                                        <TimeInput
                                            onChange={event => setStartTime(event.detail.value)}
                                            value={startTime}
                                            format="hh:mm:ss"
                                            placeholder="hh:mm:ss"
                                        />
                                    </FormField>
                                    <br/>
                                    <br/>
                                    <FormField label={'End Date'}>
                                        <DatePicker
                                            onChange={event => setEndDate(event.detail.value)}
                                            value={endDate}
                                            openCalendarAriaLabel={selectedDate =>
                                                "Choose Date" +
                                                (selectedDate
                                                    ? `, selected date is ${selectedDate}`
                                                    : "")
                                            }
                                            nextMonthAriaLabel="Next month"
                                            placeholder="YYYY/MM/DD"
                                            previousMonthAriaLabel="Previous month"
                                            todayAriaLabel="Today"
                                        />
                                    </FormField>
                                    <FormField label={'End Time'}>
                                        <TimeInput
                                            onChange={event => setEndTime(event.detail.value)}
                                            value={endTime}
                                            format="hh:mm:ss"
                                            placeholder="hh:mm:ss"
                                        />
                                    </FormField>
                                    <br/>
                                    <br/>
                                    <FormField label = {'Temporary Credentials For ADMS Account'}>
                                    <Input
                                        onChange={event => setAdmsCredentials(event.detail.value)}
                                        value={admsCredentials}
                                    />
                                    </FormField>
                                    <br/>
                                    <br/>
                                    <FormField label = {'Temporary Credentials For Account to Query'}>
                                        <Input
                                            onChange={event => setCredentials(event.detail.value)}
                                            value={credentials}
                                        />
                                    </FormField>
                                    <br/>
                                    <br/>
                                    <FormField label = {'Email Address'}>
                                        <Input
                                            onChange={event => setEmail(event.detail.value)}
                                            value={email}
                                        />
                                    </FormField>
                                </Form>
                            </form>
                        </Container>
                    </SpaceBetween>
                </Box>
            </TextContent>
        </div>
    );
}

const CustomNavigation = () => (
    <SideNavigation
        header={{ text: "Navigation Panel", href: "#/" }}
        items={[
            {type: "link", text: "Customer Impact Report Generator", href: "#/"},
            {type: "link", text: "Move SQS Messages", href: "#/MoveSQSMessages"},
            {type: "link", text: "Stream Kona files", href: "#/StreamKonaFiles"},
            {type: "link", text: "Submit Metering Amendment Files", href: "#/MeteringAmendmentFiles"},
            {type: "link", text: "Weigh away an impacted AZ", href: "#/WeighAwayAZ"},
            {type: "link", text: "Weigh in back an impacted AZ", href: "#/WeighInAZ"},
            {type: "link", text: "Covert AccountId to PlatformToken", href: "#/ConvertAccountIdToToken"}
        ]}
        activeHref="#/"
    />
);

const Tools = () => (
    <HelpPanel header={<h2>Help panel</h2>}>
        <p>Help content goes here</p>
    </HelpPanel>
);
