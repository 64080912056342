/* eslint-disable */

const host =
  window.location.hostname === "localhost"
    ? "http://localhost:3000"
    : "https://" + window.location.hostname;
const EnvAttributes = () => {
  switch (window.location.hostname) {
    case "localhost":
    case "cvmoore.people.aws.dev":
      return {
        CognitoDomain: "workshop-cvmoore.auth.us-west-2.amazoncognito.com",
        CognitoIPool: "us-west-2:3657d092-98c5-4cd9-ae71-f91d7533e537",
        CognitoUPool: "us-west-2_mAna2lYGI",
        CognitoWebClientID: "3j077bo0mkec7pi3fv0hitifvl",
      };
  }
};

const awsmobile = {
  aws_project_region: "us-west-2",
  aws_cognito_identity_pool_id: EnvAttributes().CognitoIPool,
  aws_cognito_region: "us-west-2",
  aws_user_pools_id: EnvAttributes().CognitoUPool,
  aws_user_pools_web_client_id: EnvAttributes().CognitoWebClientID,
  oauth: {
    domain: EnvAttributes().CognitoDomain,
    scope: ["profile", "openid", "aws.cognito.signin.user.admin"],
    redirectSignIn: host,
    redirectSignOut: host,
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
};

export default awsmobile;
